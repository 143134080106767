import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Customer } from '../../../lib/customer/types';
import { RootState } from '../../../redux';
import { Category } from '../../../redux/categories/types';
import { excelReset, submitExcel } from '../../../redux/orders/actions';
import ExcelFailModal from '../modal/ExcelFailModal';
import ExcelLoadModal from '../modal/ExcelLoadModal';
import ExcelSuccessModal from '../modal/ExcelSuccessModal';

type ExcelState = {
    content: string | ArrayBuffer | null;
    name?: string;
    loading: boolean;
    loadingFile: boolean;
};

const emptyExcelState: ExcelState = {
    content: null,
    name: undefined,
    loading: false,
    loadingFile: false,
};

interface Props {
    category: Category;
    customer: Customer | null;
    hidden: boolean;
}

const ExcelLoad = ({ category, customer, hidden }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showExcelFail, showExcelSuccess } = useSelector((state: RootState) => state.orders);

    const [excel, setExcel] = useState<ExcelState>(emptyExcelState);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setExcel(emptyExcelState);
    };

    const handleSuccessClose = () => {
        handleClose();
        navigate('/');
    };

    const handleFailClose = () => {
        handleClose();
        dispatch(excelReset());
    };

    const handleFileInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] ?? null;
        if (!file) return;

        setExcel((excel) => ({ ...excel, loadingFile: true }));

        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            setExcel({
                content: e.target !== null ? e.target.result : null,
                name: file.name,
                loading: false,
                loadingFile: false,
            });
        };
        fileReader.readAsDataURL(file);
    };

    const handleSubmit = () => {
        dispatch(submitExcel(category.id, excel.name || '', excel.content, customer!.id));
        setExcel((excel) => ({ ...excel, loading: true }));
    };

    return (
        <>
            {!hidden && (
                <button
                    className='btn btn-secondary btn-w-icon'
                    onClick={() => setOpen(true)}
                    disabled={!customer?.excelAllowed}
                >
                    Lataa excel
                </button>
            )}

            <ExcelLoadModal
                disabled={excel.content === null}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleFileInputOnChange={handleFileInputOnChange}
                loading={excel.loading}
                loadingFile={excel.loadingFile}
                name={excel.name}
                open={open && !showExcelFail && !showExcelSuccess}
            />
            <ExcelSuccessModal handleClose={handleSuccessClose} open={showExcelSuccess} />
            <ExcelFailModal handleClose={handleFailClose} open={showExcelFail} />
        </>
    );
};

export default ExcelLoad;
