import { FC, FormEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { StylesConfig } from 'react-select';

import CloseIcon from '../../assets/images/icons/close.svg';
import CustomerPicker, { CustomOptionType } from '../../components/customer-picker';
import LoadingIcon from '../../components/loading-icon';
import OrderList from '../../components/order-list';
import { Customer } from '../../lib/customer/types';
import { RootState } from '../../redux';
import {
    checkSavedOrders,
    deleteSavedOrder,
    deleteSavedOrderClear,
    fetchSavedOrders,
} from '../../redux/saved-orders/actions';
import ListItemAction from './list-item-action';

const selectStyles: StylesConfig<CustomOptionType, false> = {
    control: (base, state) => ({
        ...base,
        borderRadius: 0,
        backgroundColor: 'white',
        borderColor: state.isFocused ? '#5d5d5d' : 'darkgrey',
        outline: 'none',
        boxShadow: 'none',
        minHeight: 'initial',
        height: '32px',
    }),
};

interface SearchState {
    searchText: string;
    customer?: Customer;
}

const SavedOrders: FC = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState<SearchState>({
        searchText: '',
        customer: undefined,
    });

    // REDUX

    const { orders, contentRange, pageSize, loading, deleteId, deleting, deleteFail } = useSelector(
        (state: RootState) => state.savedOrders,
    );
    const { importingOrder } = useSelector((state: RootState) => state.orders);

    const isAdmin = useSelector((state: RootState) => (state.oidc.user?.profile.roles ?? []).indexOf('ADMIN') !== -1);

    // On mount
    useEffect(() => {
        dispatch(fetchSavedOrders(0, {}));
        document.title = 'Tilausportaali - Tallennetut tilaukset';

        // This is necessary to remove the empty order that is being removed when coming straight from
        // the category page
        setTimeout(() => {
            dispatch(fetchSavedOrders(0, {}));
        }, 500);
    }, []);

    useEffect(() => {
        dispatch(checkSavedOrders());
    }, [orders]);

    const handleCloseModal = () => {
        dispatch(deleteSavedOrderClear());
    };
    const handleDeleteSavedOrder = () => {
        dispatch(deleteSavedOrder());
    };

    const handleFetchSavedOrders = (page: number) => {
        const filter = {
            ...(search.searchText && { q: search.searchText }),
            ...(search.customer && { customer: search.customer.id }),
        };
        dispatch(fetchSavedOrders(page, filter));
    };

    const handleSearchSubmit = (e: FormEvent) => {
        e.preventDefault();
    };

    return (
        <div className='small-wrapper'>
            <h1>Tallennetut tilaukset</h1>

            <form className='order-info__search' onSubmit={handleSearchSubmit}>
                {isAdmin && (
                    <CustomerPicker
                        setSelectedCustomer={(customer) => setSearch({ ...search, customer })}
                        selectedCustomer={search.customer}
                        type='simple'
                        selectStyles={selectStyles}
                    />
                )}
                <div className='order-info__search-input-wrapper'>
                    <label htmlFor='old-orders-search-text'>Viite/Merkki</label>
                    <input
                        type='text'
                        id='old-orders-search-text'
                        className='order-info__search-input order-info__search-text'
                        value={search.searchText}
                        onChange={(e) => setSearch({ ...search, searchText: e.target.value })}
                    />
                </div>
                <div className='order-info__search-input-wrapper'>
                    <label style={{ color: 'rgba(0, 0, 0, 0)' }}>.</label>
                    <button
                        className='order-info__search-input btn btn-primary'
                        onClick={() => handleFetchSavedOrders(0)}
                        disabled={loading || importingOrder}
                    >
                        Etsi
                    </button>
                </div>
            </form>

            {/* RESULTS */}

            <OrderList
                list={orders}
                contentRange={contentRange}
                pageSize={pageSize}
                loading={loading || importingOrder}
                fetchOrders={handleFetchSavedOrders}
                listItemAction={ListItemAction}
                filters={{
                    ...(search.searchText && { q: search.searchText }),
                    ...(search.customer && { customer: search.customer }),
                }}
            />

            {/* Delete saved order */}
            <Modal isOpen={deleteId !== null} onRequestClose={handleCloseModal} ariaHideApp={false}>
                <button className='modal__close btn btn-icon' onClick={handleCloseModal}>
                    <img src={CloseIcon} alt='sulje' />
                </button>
                <div className='modal__title-wrap'>
                    {deleteFail && (
                        <div className='modal__title modal__title--center'>
                            Tallennetun tilauksen poistamisessa meni jotain vikaan. Koeta uudelleen myöhemmin.
                        </div>
                    )}
                    {deleting && (
                        <div className='modal__title modal__title--center'>
                            <LoadingIcon />
                        </div>
                    )}
                    {!deleteFail && !deleting && (
                        <div className='modal__title modal__title--center'>
                            Oletko varma että haluat poistaa tämän tallennetun tilauksen? Tätä ei voi peruuttaa.
                        </div>
                    )}
                </div>
                <div className='modal__actions btn-row stack-element'>
                    <button className='btn btn-secondary' onClick={handleCloseModal}>
                        Peruuta
                    </button>
                    <button className='btn btn-primary' onClick={handleDeleteSavedOrder} disabled={deleteFail}>
                        Poista
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default SavedOrders;
