import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as oidcReducer } from 'redux-oidc';

import announcements, {
    downloadAnnouncementFileEpic,
    initAnnouncementsEpic,
    readAnnouncementsEpic,
} from './announcements';
import categories, { getCategoryEpic, getCategoryLinksEpic } from './categories';
import config, { initConfigEpic } from './config';
import fixOrder, { continueFixOrderEpic, loadFixOrderEpic } from './fix-order';
import oldOrders, { downloadOrderPdfEpic, fetchOldOrdersEpic } from './old-orders';
import orders, { importExcelEpic, pingExcelEpic, submitExcelEpic } from './orders';
import savedOrders, {
    checkSavedOrdersEpic,
    continueSavedOrderEpic,
    deleteSavedOrderEpic,
    fetchSavedOrdersEpic,
    importSavedOrderEpic,
} from './saved-orders';

const rootReducer = combineReducers({
    oidc: oidcReducer,
    categories,
    orders,
    config,
    oldOrders,
    savedOrders,
    fixOrder,
    announcements,
});

export type RootState = ReturnType<typeof rootReducer>;

export const rootEpic = combineEpics(
    getCategoryLinksEpic,
    getCategoryEpic,
    initConfigEpic,
    submitExcelEpic,
    pingExcelEpic,
    importExcelEpic,
    fetchOldOrdersEpic,
    downloadOrderPdfEpic,
    checkSavedOrdersEpic,
    fetchSavedOrdersEpic,
    deleteSavedOrderEpic,
    continueSavedOrderEpic,
    importSavedOrderEpic,
    loadFixOrderEpic,
    continueFixOrderEpic,
    downloadAnnouncementFileEpic,
    initAnnouncementsEpic,
    readAnnouncementsEpic,
);

export default rootReducer;
